export default {
    // Data
    UPLOAD_DATA: 'UPLOAD_DATA',
    CONNECT_DATA: 'CONNECT_DATA',
    CHANGE_DATA: 'CHANGE_DATA',
    VISUALIZE_DATA: 'VISUALIZE_DATA',
    UPDATE_DATA_URL: 'UPDATE_DATA_URL',
    // Fact
    ADD_FACT: 'ADD_FACT',
    INSERT_FACT: 'INSERT_FACT',
    INSERT_RECOMMENDEDFACT: 'INSERT_RECOMMENDEDFACT',
    UPDATE_FACT: 'UPDATE_FACT',
    DELETE_FACT: 'DELETE_FACT',
    DELETE_UNUSEDFACT: 'DELETE_UNUSEDFACT',
    ORDER_FACTS: 'ORDER_FACTS',
    SET_HOVER_INDEX: 'SET_HOVER_INDEX',
    UPDATE_FILTERS:'UPDATE_FILTERS',
    UPDATE_FILTERS_TITLE:'UPDATE_FILTERS_TITLE',
    UPDATE_DATARES:'UPDATE_DATARES',
    UPDATE_SCHEMA_FILTER:'UPDATE_SCHEMA_FILTER',
    UPDATE_FILTERS_CHANGE_FLAG:'UPDATE_FILTERS_CHANGE_FLAG',
    CHANGE_CURRENT_EDITING:'CHANGE_CURRENT_EDITING',
    UPDATE_FILTERS_LOCAL: 'UPDATE_FILTERS_LOCAL', 
    UPDATE_PANNEL_WIDTH:'UPDATE_PANNEL_WIDTH',

    // Story
    INIT_FACT: 'INIT_FACT',
    INIT_RELATION: 'INIT_RELATION',
    SELECT_FACT: 'SELECT_FACT',
    CHANGE_METHOD: 'CHANGE_METHOD',
    SET_STORY_PARAMETER: 'SET_STORY_PARAMETER',
    SET_AGGREGATION_LEVEL: 'SET_AGGREGATION_LEVEL',
    SET_REWARD_WEIGHT: 'SET_REWARD_WEIGHT',
    GENERATE_STORY: 'GENERATE_STORY',
    CHANGE_TITLE: 'CHANGE_TITLE',
    CHANGE_SUBTITLE: 'CHANGE_SUBTITLE',
    UPDATE_STORY_CARD_NUMBER: 'UPDATE_STORY_CARD_NUMBER',
    UPDATE_COMMENT_STORY_INFO: 'UPDATE_COMMENT_STORY_INFO',
    UPDATE_UUID: 'UPDATE_UUID',
    UPDATE_PROGRESS: 'UPDATE_PROGRESS',//生成进度
    EXPORT_PDF: 'EXPORT_PDF',//导出pdf
    // new
    CHANGE_TO_ANIMATION: 'CHANGE_TO_ANIMATION',//change facts to animated facts
    //algorithm
    CHANGE_ALGORITHM:'CHANGE_ALGORITHM',
    ADD_SFACT:'ADD_SFACT',
    ADD_EFACT:'ADD_EFACT',
    ADD_MFACT:'ADD_MFACT',
    DELETE_SFACT:'DELETE_SFACT',
    DELETE_EFACT:'DELETE_EFACT',
    DELETE_MFACT:'DELETE_MFACT',
    OPTIMIZE_LAYOUT:'OPTIMIZE_LAYOUT',
    INSERT_EMPTY_FACT:'INSERT_EMPTY_FACT',
    INSERT_INTERPOLATED_FACTS:'INSERT_INTERPOLATED_FACT',
    CHANGE_INTERPOLATIONFLAG:'CHANGE_INTERPOLATIONFLAG',
    UNDO:'UNDO',
    REDO:'REDO',
    //user
    UPDATE_USER_INFO: 'UPDATE_USER_INFO',
    UPDATE_USER_OPERATION: 'UPDATE_USER_OPERATION',
    UPDATE_CONVERT_TYPE: 'UPDATE_CONVERT_TYPE',
    UPDATE_LOCALE: 'UPDATE_LOCALE',
    UPDATE_COLLAPSED: 'UPDATE_COLLAPSED',
    UPDATE_LEFT_COLLAPSED: 'UPDATE_LEFT_COLLAPSED',
    UPDATE_HEADERBAR_TOGGLE: 'UPDATE_HEADERBAR_TOGGLE',
    OPEN_LEFT_FOLAT_MENUE: 'OPEN_LEFT_FOLAT_MENUE',
    UPDATE_CLICKED_MENUE: 'UPDATE_CLICKED_MENUE',
    CHANGE_LAYOUT_TYPE: "CHANGE_LAYOUT_TYPE",
    CHANGE_USEREND: 'CHANGE_USEREND',
    WINDOW_UP_SCROLL: 'WINDOW_UP_SCROLL',
    UPDATA_USER_END_SIZE: 'UPDATA_USER_END_SIZE',
    IS_HIDDEN_INDEX_LEFT_MENUE: 'IS_HIDDEN_INDEX_LEFT_MENUE',
    IS_HIDDEN_COMMENT_STORY: 'IS_HIDDEN_COMMENT_STORY',
    UPDATE_LAST_PAGE: 'UPDATE_LAST_PAGE',
    UPDATE_SID: 'UPDATE_SID',
    SHARE_STORY: 'SHARE_STORY',
    IS_GENERATE_STORY: 'IS_GENERATE_STORY',
    TO_NEW_PAGE: 'TO_NEW_PAGE',
    IS_CLICKED_LOGOUT: 'IS_CLICKED_LOGOUT',
    LEAVE_CREATE_PAGE: 'LEAVE_CREATE_PAGE',
    IS_INTERRUPT_PROCESS: 'IS_INTERRUPT_PROCESS',
    CHANGE_FACTSHEET_LAYOUT_TYPE:'CHANGE_FACTSHEET_LAYOUT_TYPE',
    //Vis
    UPDATE_VIS_CONTAINER_SIZE: 'UPDATE_VIS_CONTAINER_SIZE'
}