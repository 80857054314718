import { facts2charts } from '../tool/fact2vis';
import ActionType from '../action/type';
import { genTitle } from "../sentencer/index";
import _ from 'lodash';
import * as d3 from 'd3';
//let visData = sessionStorage.getItem('visData') ? JSON.parse(sessionStorage.getItem('visData')) : {};
let uuid = localStorage.getItem('uuid') ? JSON.parse(sessionStorage.getItem('uuid')) : null;
const plur = require('plur');

const convertField = function (field, param = 'single') {
    let gb = field
    if (param === 'single')
        return gb
    else if (param === 'plural') {
        if (gb.indexOf(' of ') !== -1) {
            let gbWords = gb.split(" ")
            let gbWordIndex = gbWords.indexOf("of") - 1
            if (gbWordIndex > -1) {
                let plurWord = plur(gbWords[gbWordIndex], 2)
                return gb.replace(gbWords[gbWordIndex], plurWord)
            }
        } else
            return plur(gb, 2)
    }
}


const initialState = {
    fileName: '.csv',
    real_filename : '.csv',
    title: '',
    subtitle: '',
    data: [],
    schema: [],
    method: 'sig',
    facts: [],
    unusedFacts: [],
    relations: [],
    hoverFactIndex: -1,
    selectedFactIndex: '',
    maxStoryLength: 6,
    timeLimit: 2000,// for each iteration
    information: 40, // default 50 bits for 6 facts
    resultCoverage: 1,
    chartDiversity: 0,
    aggregationLevel: 0,
    rewardWeight: {
        logicality: 0.33,
        diversity: 0.33,
        integrity: 0.33,
    },
    generateProgress: 0,
    visData: {},
    uuid,
    historyStory: '',
    isExportPdf: true,
    generateUrl: '',//dataUrl for H5,

    //edit filters
    filters: [],
    filtersTitle: '',
    datares: [],
    schemaFilter: [],
    filtersChangeFlag: false,
    editstate: false,
    editingCurrentFact: null,
    //local filters
    filtersLocal: [],
    filtersTitleLocal: '',
    dataresLocal: [],
    schemaFilterLocal: [],
    leftPaneWidth:'21%',//left pannel
    rightPaneWidth:'21%',
    //origin filters
    filtersOrigin:[],
    filtersTitleOrigin:'',

    animationFlag: false, //this button is to change the facts to animated facts
    animationType: "顺序播放",

    //algorithm select
    algorithm:'MCTS',
    calSimilarityType:'trans',
    maximumFact:1,
    recommendedSfacts:[],
    recommendedEfacts:[],
    recommendedMfacts:[],
    layoutFlag:false,
    interpolationFlag:false,
    //meta
    specIndex:-1,//状态为0时 清空edit页面
    timeline: [],


};

export default (state = initialState, action) => {
    const newState = Object.assign({}, state);
    let newFacts;
    let newFactsCopy;
    let specIndex1;
    switch (action.type) {
        case ActionType.INIT_RELATION:
            newState.relations = action.storyRelations;
            newState.resultCoverage = 0;
            return newState;
        case ActionType.INIT_FACT:
            newState.facts = action.storyFacts;
            newState.resultCoverage = 0;
            return newState;
        case ActionType.SELECT_FACT:
            newState.selectedFactIndex = action.index;
            return newState;
        case ActionType.ADD_FACT:
            newFacts = newState.unusedFacts.slice();
            newState.unusedFacts = newFacts.concat(action.facts);;
            return newState;
        case ActionType.UPDATE_FACT:
            newState.selectedFactIndex = action.index;
            let fact = _.cloneDeep(action.fact);
            // fact.chart = getFactChartType(fact, state.data);
            newFacts = newState.facts.slice();
            newFacts[action.index] = fact;
            newState.facts = newFacts;
            newState.relations = Array(newState.facts.length).fill('none'); // reset relations
            // newState.timeline.push(newState)
            newState.specIndex ++;
            specIndex1=newState.specIndex
            newState.timeline.splice(specIndex1,newState.timeline.length-specIndex1,newState)
            return newState;
        case ActionType.INSERT_FACT:
            newFacts = newState.facts.slice();
            let newUnusedFacts = newState.unusedFacts.slice();
            let newFact = newUnusedFacts.splice(action.index, 1)[0];
            newFacts.splice(action.insert, 0, newFact);
            newFactsCopy=_.cloneDeep(newFacts)
            newFactsCopy=newFactsCopy.map((item,i)=>{
                item.index=i
                return item
            })
            newState.facts = newFactsCopy;
            newState.unusedFacts = newUnusedFacts;
            newState.relations = Array(newFacts.length).fill('none'); // reset relations
            newState.specIndex ++;
            specIndex1=newState.specIndex
            newState.timeline.splice(specIndex1,newState.timeline.length-specIndex1,newState)
            return newState;
        case ActionType.INSERT_RECOMMENDEDFACT:
            newFacts = newState.facts.slice();
            let newUnusedRFacts;
            if(action.factLocation==="first") {
                newUnusedRFacts = newState.recommendedSfacts.slice();
            }
            else if(action.factLocation==="last") {
                newUnusedRFacts = newState.recommendedEfacts.slice();
            }
            else newUnusedRFacts = newState.recommendedMfacts.slice();
            let newFact1 = newUnusedRFacts.splice(action.index, 1)[0];
            newFacts.splice(action.insert, 0, newFact1);
            newFactsCopy=_.cloneDeep(newFacts)
            newFactsCopy=newFactsCopy.map((item,i)=>{
                item.index=i
                return item
            })
            newState.facts = newFactsCopy;
            // newState.unusedFacts = newUnusedFacts;
            newState.relations = Array(newFacts.length).fill('none'); // reset relations
            newState.specIndex ++;
            specIndex1=newState.specIndex
            newState.timeline.splice(specIndex1,newState.timeline.length-specIndex1,newState)
            return newState;
        case ActionType.DELETE_FACT:
            newFacts = newState.facts.slice();
            newFacts.splice(action.index, 1);
            // newState.selectedFactIndex = action.index > 0 ? action.index - 1 : 0;
            if (newFacts.length === 0) {
                // add default fact
                // let newFact = new Fact(FactType.VALUE);
                // newFacts.push(newFact);
            }
            newFactsCopy=_.cloneDeep(newFacts)
            newFactsCopy=newFactsCopy.map((item,i)=>{
                item.index=i
                return item
            })
            newState.facts = newFactsCopy;
            newState.selectedFactIndex = '';
            // newState.selectedFactIndex = newFacts.length - 1;
            newState.relations = Array(newState.facts.length).fill('none'); // reset relations
            newState.specIndex ++;
            specIndex1=newState.specIndex
            newState.timeline.splice(specIndex1,newState.timeline.length-specIndex1,newState)
            return newState;
        case ActionType.DELETE_UNUSEDFACT:
            newFacts = newState.unusedFacts.slice();
            newFacts.splice(action.index, 1);
            newState.unusedFacts = newFacts;
            return newState;
        case ActionType.ORDER_FACTS:
            newFacts = newState.facts.slice();
            const [moved] = newFacts.splice(action.sourceIndex, 1);
            newFacts.splice(action.destinationIndex, 0, moved);
            // newFacts.splice(action.sourceIndex < action.destinationIndex ? action.destinationIndex - 1 : action.destinationIndex, 0, moved);
            newFactsCopy=_.cloneDeep(newFacts)
            newFactsCopy=newFactsCopy.map((item,i)=>{
                item.index=i
                return item
            })
            newState.facts = newFactsCopy;
            newState.selectedFactIndex = action.destinationIndex;
            newState.relations = Array(newState.facts.length).fill('none'); // reset relations
            newState.specIndex ++;
            specIndex1=newState.specIndex
            newState.timeline.splice(specIndex1,newState.timeline.length-specIndex1,newState)
            return newState;
        case ActionType.SET_HOVER_INDEX:
            newState.hoverFactIndex = action.index;
            return newState;
        case ActionType.CHANGE_METHOD:
            newState.method = action.method;
            return newState;
        case ActionType.SET_STORY_PARAMETER:
            newState.maxStoryLength = action.maxStoryLength;
            newState.information = action.information;
            newState.chartDiversity = action.chartDiversity;
            if (action.chartDiversity !== state.chartDiversity) {
                newFacts = newState.facts.slice();
                //newFacts = facts2charts(newFacts, state.data, action.chartDiversity);
                newFacts = facts2charts(newFacts, state.schema, action.chartDiversity);
                newState.facts = newFacts;
            }
            newState.timeLimit = action.timeLimit;
            return newState;

        case ActionType.SET_AGGREGATION_LEVEL:
            newState.aggregationLevel = action.level;
            return newState;

        case ActionType.SET_REWARD_WEIGHT:
            let newWeight = Object.assign({}, newState.rewardWeight);
            newWeight.logicality = action.logicality;
            newWeight.diversity = action.diversity;
            newWeight.integrity = action.integrity;
            newState.rewardWeight = newWeight;
            return newState;

        case ActionType.GENERATE_STORY:
            newState.facts = action.facts;
            newState.relations = action.relations;
            newState.resultCoverage = action.coverage;
            // newState.selectedFactIndex = action.facts.length - 1
            newState.selectedFactIndex = ''
            newState.specIndex ++;
            specIndex1=newState.specIndex
            newState.timeline.splice(specIndex1,newState.timeline.length-specIndex1,newState)
            return newState;

        case ActionType.UPDATE_PROGRESS:
            newState.generateProgress = Number(((action.totalLength - action.currentLength) / action.totalLength).toFixed(2) * 100);
            return newState;
        case ActionType.EXPORT_PDF:
            newState.isExportPdf = action.isExportPdf;
            return newState;
        case ActionType.CHANGE_TITLE:
            newState.title = action.title;
            newState.specIndex ++;
            specIndex1=newState.specIndex
            newState.timeline.splice(specIndex1,newState.timeline.length-specIndex1,newState)
            return newState;
        case ActionType.CHANGE_SUBTITLE:
            newState.subtitle = action.subtitle;
            newState.specIndex ++;
            specIndex1=newState.specIndex
            newState.timeline.splice(specIndex1,newState.timeline.length-specIndex1,newState)
            return newState;
        case ActionType.CHANGE_CURRENT_EDITING:
            newState.editstate = action.editstate;
            newState.editingCurrentFact = action.editingCurrentFact;
            return newState;
        case ActionType.CHANGE_ALGORITHM:
            newState.algorithm=action.algorithm;
            newState.calSimilarityType=action.calSimilarityType;
            newState.maximumFact=action.maximumFact;
            return newState;
        case ActionType.ADD_SFACT://starting_facts
            newFacts = newState.recommendedSfacts.slice();
            newState.recommendedSfacts = newFacts.concat(action.facts);
            return newState;
        case ActionType.ADD_EFACT://ending_facts
            newFacts = newState.recommendedEfacts.slice();
            newState.recommendedEfacts = newFacts.concat(action.facts);
            return newState;
        case ActionType.ADD_MFACT://middle_facts
            // newState.recommendedMfacts=action.facts
            newFacts = newState.recommendedMfacts.slice();
            newState.recommendedMfacts = newFacts.concat(action.facts);
            return newState;
        case ActionType.DELETE_SFACT://delete starting_facts
            newFacts = newState.recommendedSfacts.slice();
            newFacts.splice(action.index, 1);
            newState.recommendedSfacts = newFacts;
            return newState;
        case ActionType.DELETE_EFACT://delete ending_facts
            newFacts = newState.recommendedEfacts.slice();
            newFacts.splice(action.index, 1);
            newState.recommendedEfacts = newFacts;
            return newState;
        case ActionType.DELETE_MFACT://delete middle_facts
            newFacts = newState.recommendedMfacts.slice();
            newFacts.splice(action.index, 1);
            newState.recommendedMfacts = newFacts;
            return newState;
        case ActionType.OPTIMIZE_LAYOUT:
            newState.layoutFlag=action.layoutFlag
            return newState;
        case ActionType.CHANGE_INTERPOLATIONFLAG:
            newState.interpolationFlag=action.interpolationFlag
            return newState

        case ActionType.INSERT_EMPTY_FACT:
            newFacts = newState.facts.slice();
            newFacts.splice(action.insert, 0, action.emptyFact);
            newFactsCopy=_.cloneDeep(newFacts)
            newFactsCopy=newFactsCopy.map((item,i)=>{
                item.index=i
                return item
            })
            newState.facts = newFactsCopy;
            newState.relations = Array(newFacts.length).fill('none'); // reset relations
            newState.specIndex ++;
            specIndex1=newState.specIndex
            newState.timeline.splice(specIndex1,newState.timeline.length-specIndex1,newState)
            return newState;
        case ActionType.INSERT_INTERPOLATED_FACTS:
            newFacts = newState.facts.slice();
            action.interpolatedFacts.unshift(action.insert,0)
            Array.prototype.splice.apply(newFacts,action.interpolatedFacts)
            newFactsCopy=_.cloneDeep(newFacts)
            newFactsCopy=newFactsCopy.map((item,i)=>{
                item.index=i
                return item
            })
            newState.facts = newFactsCopy;
            newState.relations = Array(newFacts.length).fill('none'); // reset relations
            newState.specIndex ++;
            specIndex1=newState.specIndex
            newState.timeline.splice(specIndex1,newState.timeline.length-specIndex1,newState)
            return newState;

        case ActionType.UPLOAD_DATA:
            newState.fileName = action.fileName;
            newState.real_filename = action.real_filename;
            newState.title = genTitle(action.real_filename);
            newState.facts = [];
            newState.relations = [];
            newState.schema = action.schema;
            newState.data = action.data;
            newState.filters = action.schema.filter(d => d.type !== 'text' && d.type !== "ID").map(d => {
                if (d.type === 'numerical') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values.map(d => d.replace(',', '')), 'value1': [Math.min(...d.values.map(d => d.replace(',', ''))), Math.max(...d.values.map(d => d.replace(',', '')))] };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values, 'value2': [0, 100], 'value3': [0, d.values.length - 1] };
                } else {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values }
                }

            });

            newState.filtersTitle = action.schema.filter(d => d.type !== 'text' && d.type !== "ID" && (d.type === 'categorical' || d.type === 'temporal'||d.type==='geographical')).map(d => {
                if (d.type === 'categorical'||d.type==='geographical') {
                    return { 'field': d.field, 'type': d.type, 'title': 'All ' + convertField(d.field, 'plural') };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'temporal', 'title': d.values[0] + '-' + d.values[d.values.length - 1] };
                }
            });
            newState.filtersOrigin = action.schema.filter(d => d.type !== 'text' && d.type !== "ID").map(d => {
                if (d.type === 'numerical') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values.map(d => d.replace(',', '')), 'value1': [Math.min(...d.values.map(d => d.replace(',', ''))), Math.max(...d.values.map(d => d.replace(',', '')))] };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values, 'value2': [0, 100], 'value3': [0, d.values.length - 1] };
                } else {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values }
                }

            });

            newState.filtersTitleOrigin = action.schema.filter(d => d.type !== 'text' && d.type !== "ID" && (d.type === 'categorical' || d.type === 'temporal'||d.type==='geographical')).map(d => {
                if (d.type === 'categorical'||d.type==='geographical') {
                    return { 'field': d.field, 'type': d.type, 'title': 'All ' + convertField(d.field, 'plural') };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'temporal', 'title': d.values[0] + '-' + d.values[d.values.length - 1] };
                }
            });
            newState.datares = action.data;
            newState.schemaFilter = action.schema;


            let subStorytitle = "Data Scope: ";
            let filtersTitleCore = '';
            if(newState.filtersTitle!==''){
                newState.filtersTitle.map((item, index) => {
                    if (item.type === "categorical" ||item.type==="geographical"|| item.type === "temporal") {
                        if (index === newState.filtersTitle.length - 1)
                            filtersTitleCore += `[${item.title}]`
                        else filtersTitleCore += `[${item.title}], `
                    }
                  
                })             
            }
            subStorytitle += filtersTitleCore
            newState.subtitle=subStorytitle

            newState.filtersLocal = action.schema.filter(d => d.type !== 'text' && d.type !== "ID").map(d => {
                if (d.type === 'numerical') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values.map(d => d.replace(',', '')), 'value1': [Math.min(...d.values.map(d => d.replace(',', ''))), Math.max(...d.values.map(d => d.replace(',', '')))] };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values, 'value2': [0, 100], 'value3': [0, d.values.length - 1] };
                } else {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values }
                }

            });

            newState.filtersTitleLocal = action.schema.filter(d => d.type !== 'text' && d.type !== "ID" && (d.type === 'categorical' || d.type === 'temporal'||d.type==='geographical')).map(d => {
                if (d.type === 'categorical'||d.type==='geographical') {
                    return { 'field': d.field, 'type': d.type, 'title': 'All ' + convertField(d.field, 'plural') };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'temporal', 'title': d.values[0] + '-' + d.values[d.values.length - 1] };
                }
            });

            newState.dataresLocal = action.data;
            newState.schemaFilterLocal = action.schema;
            //  newState.filtersChangeFlag = false;
            //console.log("UPLOAD_DATA",newState.data)
            return newState
        case ActionType.CONNECT_DATA:
            newState.fileName = action.fileName;
            newState.real_filename = action.real_filename;
            newState.title = genTitle(action.real_filename);
            newState.facts = [];
            newState.relations = [];
            newState.schema = action.schema;
            newState.data = action.data;
            newState.resultCoverage = 0;

            newState.filters = action.schema.filter(d => d.type !== 'text' && d.type !== "ID").map(d => {
                if (d.type === 'numerical') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values.map(d => d.replace(',', '')), 'value1': [Math.min(...d.values.map(d => d.replace(',', ''))), Math.max(...d.values.map(d => d.replace(',', '')))] };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values, 'value2': [0, 100], 'value3': [0, d.values.length - 1] };
                } else {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values }
                }

            });
            newState.filtersTitle = action.schema.filter(d => d.type !== 'text' && d.type !== "ID" && (d.type === 'categorical' || d.type === 'temporal'||d.type==='geographical')).map(d => {
                if (d.type === 'categorical'||d.type==='geographical') {
                    return { 'field': d.field, 'type': d.type, 'title': 'All ' + convertField(d.field, 'plural') };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'temporal', 'title': d.values[0] + '-' + d.values[d.values.length - 1] };
                }
            });
            newState.filtersOrigin = action.schema.filter(d => d.type !== 'text' && d.type !== "ID").map(d => {
                if (d.type === 'numerical') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values.map(d => d.replace(',', '')), 'value1': [Math.min(...d.values.map(d => d.replace(',', ''))), Math.max(...d.values.map(d => d.replace(',', '')))] };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values, 'value2': [0, 100], 'value3': [0, d.values.length - 1] };
                } else {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values }
                }

            });

            newState.filtersTitleOrigin = action.schema.filter(d => d.type !== 'text' && d.type !== "ID" && (d.type === 'categorical' || d.type === 'temporal'||d.type==='geographical')).map(d => {
                if (d.type === 'categorical'||d.type==='geographical') {
                    return { 'field': d.field, 'type': d.type, 'title': 'All ' + convertField(d.field, 'plural') };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'temporal', 'title': d.values[0] + '-' + d.values[d.values.length - 1] };
                }
            });

            newState.datares = action.data;
            newState.schemaFilter = action.schema;
            newState.filtersLocal = action.schema.filter(d => d.type !== 'text' && d.type !== "ID").map(d => {
                if (d.type === 'numerical') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values.map(d => d.replace(',', '')), 'value1': [Math.min(...d.values.map(d => d.replace(',', ''))), Math.max(...d.values.map(d => d.replace(',', '')))] };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values, 'value2': [0, 100], 'value3': [0, d.values.length - 1] };
                } else {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values }
                }

            });

            newState.filtersTitleLocal = action.schema.filter(d => d.type !== 'text' && d.type !== "ID" && (d.type === 'categorical' || d.type === 'temporal'||d.type==='geographical')).map(d => {
                if (d.type === 'categorical'||d.type==='geographical') {
                    return { 'field': d.field, 'type': d.type, 'title': 'All ' + convertField(d.field, 'plural') };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'temporal', 'title': d.values[0] + '-' + d.values[d.values.length - 1] };
                }
            });
            newState.dataresLocal = action.data;
            newState.schemaFilterLocal = action.schema;
            //  newState.filtersChangeFlag = false;
            //console.log("CONNECT_DATA", newState)
            return newState
        case ActionType.UPDATE_COMMENT_STORY_INFO:
            //sessionStorage.setItem('storyData', JSON.stringify(action.visData));
            newState.facts = action.facts;
            newState.relations = action.relations;
            newState.schema = action.schema;
            newState.data = action.data;
            newState.title = action.title || '';
            newState.subtitle = action.subtitle || '';
            newState.fileName = action.fileName || '';
            newState.real_filename = action.real_filename || '';
            newState.storyParameter = action.storyParameter
            newState.aggregationLevel = action.aggregationLevel;
            newState.resultCoverage = action.resultCoverage;
            newState.historyStory = {
                historyfacts: newState.facts,
                historyTitle: newState.title,
                historySubtitle: newState.subtitle,
                historStoryParameter: newState.storyParameter,
                historyAggregationLevel: newState.aggregationLevel,
                historyResultCoverage: newState.resultCoverage
            }

            newState.filters = action.schema.filter(d => d.type !== 'text' && d.type !== "ID").map(d => {
                if (d.type === 'numerical') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values.map(d => d.replace(',', '')), 'value1': [Math.min(...d.values.map(d => d.replace(',', ''))), Math.max(...d.values.map(d => d.replace(',', '')))] };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values, 'value2': [0, 100], 'value3': [0, d.values.length - 1] };
                } else {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values }
                }

            });
            newState.filtersTitle = action.schema.filter(d => d.type !== 'text' && d.type !== "ID" && (d.type === 'categorical' || d.type === 'temporal'||d.type==='geographical')).map(d => {
                if (d.type === 'categorical'||d.type==='geographical') {
                    return { 'field': d.field, 'type': d.type, 'title': 'All ' + convertField(d.field, 'plural') };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'temporal', 'title': d.values[0] + '-' + d.values[d.values.length - 1] };
                }
            });

            newState.datares = action.data;
            newState.schemaFilter = action.schema;

            newState.filtersLocal = action.schema.filter(d => d.type !== 'text' && d.type !== "ID").map(d => {
                if (d.type === 'numerical') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values.map(d => d.replace(',', '')), 'value1': [Math.min(...d.values.map(d => d.replace(',', ''))), Math.max(...d.values.map(d => d.replace(',', '')))] };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values, 'value2': [0, 100], 'value3': [0, d.values.length - 1] };
                } else {
                    return { 'field': d.field, 'type': 'equal', 'value': d.values }
                }

            });

            newState.filtersTitleLocal = action.schema.filter(d => d.type !== 'text' && d.type !== "ID" && (d.type === 'categorical' || d.type === 'temporal'||d.type==='geographical')).map(d => {
                if (d.type === 'categorical'||d.type==='geographical') {
                    return { 'field': d.field, 'type': d.type, 'title': 'All ' + convertField(d.field, 'plural') };
                } else if (d.type === 'temporal') {
                    return { 'field': d.field, 'type': 'temporal', 'title': d.values[0] + '-' + d.values[d.values.length - 1] };
                }
            });
            newState.dataresLocal = action.data;
            newState.schemaFilterLocal = action.schema;
            //  newState.filtersChangeFlag = false;
            return newState
        case ActionType.VISUALIZE_DATA:
            // sessionStorage.setItem('visData', JSON.stringify(action.visData));
            newState.visData = action.visData
            return newState
        case ActionType.UPDATE_UUID:
            localStorage.setItem('uuid', JSON.stringify(action.uuid));
            newState.uuid = action.uuid
            return newState
        case ActionType.UPDATE_DATA_URL:
            newState.generateUrl = action.generateUrl
            return newState
        //change facts to animated facts
        case ActionType.CHANGE_TO_ANIMATION:
            newState.animationFlag = action.animationFlag
            return newState

        //pannel width update
        case ActionType.UPDATE_PANNEL_WIDTH:
            newState.leftPaneWidth = action.leftPaneWidth
            newState.rightPaneWidth = action.rightPaneWidth
            return newState
            
        //editor filters
        case ActionType.UPDATE_FILTERS:
            let schemaNoTextID = _.cloneDeep(newState.schema).filter(d => d.type !== 'text' && d.type !== "ID")

            let dataclone = _.cloneDeep(newState.data);
            let datares1 = [];
            let filters = action.filters;
            filters.map((item, index) => {
                datares1 = [];

                let itemfield = item.field;//Brand
                item.value.map(item1 => {//BMW,Ford
                    if (schemaNoTextID[index].type === "numerical") datares1.push(...dataclone.filter(filter => filter[itemfield] == parseFloat(item1)))
                    else datares1.push(...dataclone.filter(filter => filter[itemfield] == item1))

                })
                dataclone = datares1;

            })
         
            let schemaFilter1 = _.cloneDeep(newState.schema)
            let filtersField = [];
            for(let i = 0; i < filters.length; i++) {
                filtersField.push(filters[i].field);
            }

            for(let i=0; i<schemaFilter1.length;i++){
                if(schemaFilter1[i].type==="ID"||schemaFilter1[i].type==="text") {continue;}
                let field = schemaFilter1[i].field;
                let filtersIndex = filtersField.indexOf(field);
                
                schemaFilter1[i].values = _.cloneDeep(filters[filtersIndex].value);
                if (schemaFilter1[i].type === "numerical") {
                    schemaFilter1[i].mean = d3.mean(filters[filtersIndex].value)
                    schemaFilter1[i].median = d3.median(filters[filtersIndex].value)
                    schemaFilter1[i].std = d3.deviation(filters[filtersIndex].value)
                    schemaFilter1[i].var = d3.variance(filters[filtersIndex].value)
                }
            }
            newState.filters = action.filters;
            newState.datares = datares1;
            newState.schemaFilter = schemaFilter1;

            return newState;
        case ActionType.UPDATE_FILTERS_TITLE:
            newState.filtersTitle = action.filtersTitle;
            return newState;
        case ActionType.UPDATE_DATARES:
            newState.datares = action.dateres;
            return newState;
        case ActionType.UPDATE_SCHEMA_FILTER:
            newState.schemaFilter = action.schemaFilter;
            return newState;
        case ActionType.UPDATE_FILTERS_CHANGE_FLAG:
            newState.filtersChangeFlag = action.filtersChangeFlag;
            return newState;
        case ActionType.UPDATE_FILTERS_LOCAL:
            newState.filtersLocal = action.filtersLocal;
            newState.schemaFilterLocal = action.schemaFilterLocal;
            newState.dataresLocal = action.dataresLocal;
            newState.filtersTitleLocal = action.filtersTitleLocal;
            return newState;
        // Meta
        case ActionType.UNDO:
            if (state.specIndex === 0) {
                return state
            }
            else {
                const newState = Object.assign({},state)
                newState.facts = state.timeline[state.specIndex-1].facts
                newState.title = state.timeline[state.specIndex-1].title
                newState.subtitle=state.timeline[state.specIndex-1].subtitle
                newState.filters=state.timeline[state.specIndex-1].filters
                newState.filtersTitle=state.timeline[state.specIndex-1].filtersTitle
                newState.datares=state.timeline[state.specIndex-1].datares
                newState.schemaFilter=state.timeline[state.specIndex-1].schemaFilter
                newState.filtersTitleLocal=state.timeline[state.specIndex-1].filtersTitleLocal
                newState.filtersLocal=state.timeline[state.specIndex-1].filtersLocal
                newState.dataresLocal=state.timeline[state.specIndex-1].dataresLocal
                newState.schemaFilterLocal=state.timeline[state.specIndex-1].schemaFilterLocal
                // newState.unusedFacts=state.timeline[state.specIndex-1].unusedFacts
                // newState.recommendedSfacts=state.timeline[state.specIndex-1].recommendedSfacts
                // newState.recommendedEfacts=state.timeline[state.specIndex-1].recommendedEfacts
                newState.recommendedMfacts=[]
                //恢复默认值
                newState.hoverFactIndex=-1;
                newState.selectedFactIndex="";
                newState.editstate=false;
                newState.editingCurrentFact=null;
                newState.specIndex--;
                return newState
            }
        case ActionType.REDO:
            if (state.specIndex === state.timeline.length-1) {
                return state
            }
            else {
                const newState = Object.assign({},state);
                newState.facts = state.timeline[state.specIndex+1].facts
                newState.title = state.timeline[state.specIndex+1].title
                newState.subtitle=state.timeline[state.specIndex+1].subtitle
                newState.filters=state.timeline[state.specIndex+1].filters
                newState.filtersTitle=state.timeline[state.specIndex+1].filtersTitle
                newState.datares=state.timeline[state.specIndex+1].datares
                newState.schemaFilter=state.timeline[state.specIndex+1].schemaFilter
                newState.filtersTitleLocal=state.timeline[state.specIndex+1].filtersTitleLocal
                newState.filtersLocal=state.timeline[state.specIndex+1].filtersLocal
                newState.dataresLocal=state.timeline[state.specIndex+1].dataresLocal
                newState.schemaFilterLocal=state.timeline[state.specIndex+1].schemaFilterLocal
                // newState.unusedFacts=state.timeline[state.specIndex+1].unusedFacts
                // newState.recommendedSfacts=state.timeline[state.specIndex+1].recommendedSfacts
                // newState.recommendedEfacts=state.timeline[state.specIndex+1].recommendedEfacts
                newState.recommendedMfacts=[]
                //恢复默认值
                newState.hoverFactIndex=-1;
                newState.selectedFactIndex="";
                newState.editstate=false;
                newState.editingCurrentFact=null;
                newState.specIndex ++;
                return newState
            }

        default:
            break;
    }
    return newState;
}